import React ,{useEffect}from "react";
import inst from "../insta.svg";
import you from "../youtube.svg";
import twitter from "../twitter.svg";
import songsvg from "../sony logo.png";
import redfm from "../red fm logo.png";
import bridge from "../bridge.png";
import sportz from "../sportz.png";
import arrow from '../arrow.svg'
import fb from './fb.svg'
import ln from './ln.svg'
import  cricket from './cricbattle-01 (1).png'
import  fancop from './fan-code-logo (1).png'
import logo from './yamuna-yodhas-circle.png'
import gsection from './UPKL.jpg'
import { Link, useLocation } from 'react-router-dom';


export default function Footer() {

  const location = useLocation();

  useEffect(() => {
    // Parse the URL parameters
    const searchParams = new URLSearchParams(location.search);
    const idParam = searchParams.get('id');

    if (idParam) {
      // Scroll to the section with the specified ID
      const section = document.getElementById(idParam);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.search]);




  const footerlogo = "https://dev-upkl.upkl.in/files/logoupkl.png";
  const logo1 = "https://dev-upkl.upkl.in/files/noida1.png";
  const logo2 = "https://dev-upkl.upkl.in/files/kashi1.png";
  const logo3 = "https://dev-upkl.upkl.in/files/Ganga1 (1).png";
  const logo4 = "https://dev-upkl.upkl.in/files/Yamuna1.png";
  const logo5 = "https://dev-upkl.upkl.in/files/awadh1.png";
  const logo6 = "https://dev-upkl.upkl.in/files/Brij 1.png";
  const logo8 = "https://dev-upkl.upkl.in/files/lucknow1.png";
  const logo7 = "https://dev-upkl.upkl.in/files/sangam1.png";
  return (
    <>
      <section className="sponsorsection" id="sponsor">
        <div className="sponsor">
      
          <div className="container-fluid">
            <div className="row">
          
            

         <img src={gsection} className="img-fluid gsection"/>


            
            </div>
          </div>
        </div>
      </section>
      <section>
        <footer>
          <div className="row">
            <div className="col-md-6 col-12 offset-md-3 offset-0">
              <div className="footermenu">
                <div className="row">
                  <div className="col-md-2 col-4">
                  <Link  className="nav-link" to="/"> <p>Home</p></Link>
                  </div>
                 
                  <div className="col-md-2 col-4">
                  <Link  className="nav-link" to="/about"> <p>ABOUT US</p></Link>
                  </div>
                  <div className="col-md-2 col-4">
                  <Link  className="nav-link" to="#"><p>NEWS </p></Link>
                  </div>
                  <div className="col-md-2 col-4">
                  <Link  className="nav-link" to="/about"><p>PLAYERS </p></Link>
                  </div>

                  <div className="col-md-2 col-4">
                  <Link  className="nav-link" to="/standing"><p>STANDINGS </p></Link>
                  </div>

                
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-10 col-12 offset-md-1 offset-0">
            <p className="policy">
            © 2024 JD NOIDA NINJA. All Rights Reserved
            </p>
          </div>
        </footer>
      </section>
    </>
  );
}
