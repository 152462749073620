import React from "react";
import Nav from "../Include/Nav";
import Footer from "../Include/Footer1";
import founder from "./founder-image-rahul-sharma.jpg";
import { Helmet } from "react-helmet";
import breadcumb from '../Frame 373 (1).png'
export default function About() {
  return (
    <>
      
      <Nav />
      <section className="breadcrumb">
        <img src={breadcumb} className="img-fluid" alt="Breadcrumb Image" />
        <div className="breadcrumb-caption">
          <div className="d-flex">
            <div className="newarrows">
              <h2 className="text-white">ABOUT US</h2>
              <div className="linetilte"></div>
            </div>
           
          </div>
        </div>
      </section>
      <section className="aboutsection">
        <div className="container-fluid">
          

          <div className="row">
            <div className="col-md-6 col-12">
              <div className="whoweareleft1">
                <img
                  src="https://t4.ftcdn.net/jpg/08/50/28/91/360_F_850289151_JTGyna7l5xiZ4Ck2uul47sEyr1WhcO0O.jpg"
                  className="img-fluid"
                />
              </div>
            </div>

            <div className="col-md-6 col-12 align-self-center">
              <div className="aboutuspara">
              <div className="titlecaption">
            <div className="d-flex">
              <div className="newarrows">
                <h2 className="text-white"> JD NOIDA NINJA</h2>
                <div className="linetilte"></div>
              </div>
            </div>
          </div>
                <p>
              

              Welcome to JD NOIDA NINJAS, a professional Kabaddi team dedicated to promoting the sport and inspiring a new generation of players. Our team is built on the values of discipline, hard work, and teamwork, and we strive to be a positive force in the Kabaddi community yet driving the youth towards the de-addiction.



                </p>
                
               
              </div>
            </div>
          </div>
        </div>

        <div className="aboutseparate"></div>
        <div className="container-fluid">
          <div className="titlecaption">
            <div className="d-flex">
              <div className="newarrows">
                <h2 className="text-white">MISSION VISION</h2>
                <div className="linetilte"></div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 col-12">
              <div className="ourmissionbox">
                <div className="title">MISSION</div>
                <p>
                Our mission is to become a leading Kabaddi team in Country. .while promoting the sport and inspiring young players to take up the game. We aim to build a strong team culture that values fair play, sportsmanship, and community engagement.

                </p>
              </div>
            </div>

            <div className="col-md-6 col-12 align-self-center">
              <div className="ourmissionbox">
                <div className="title">VALUES</div>
                <p>
                Our Kabaddi team embodies discipline, teamwork, and respect, striving for success both on and off the mat. Committed to our community, we promote Kabaddi and youth involvement in sports, emphasizing dedication and focus.
                  <br></br>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* 
      <section className="videoplayer">
        <div class="custom-container">
          <div class="custom-background-image"></div>
          <div class="custom-overlay"></div>
          <div class="custom-content">
            <h1>
              WATCH THE AMAZING <br></br>TRAINING ATMOSPHERE<br></br>AT OUR CLUB
              WITH TALENT COACHES
              UPKL 2024 FOR DELCATRATION ALL BIGS SCC
            </h1>
          </div>
          <div class="custom-play-button">
            <img src={icon} className="img-fluid" />
          </div>
        </div>
      </section> */}

      <section className="aboutfounder">
        <div className="container-fluid">
        

          <div className="founderbox">
            <div className="row">
              <div className="col-md-12 col-12 align-self-center">
                <div className="founderright">
                  <div className="foundermessage "></div>
                </div>
              </div>
              <div className="col-md-12 col-12 align-self-center">
                <div className="foundermessage foundermessage1">
                <div className="titlecaption">
            <div className="d-flex">
              <div className="newarrows">
                <h2 className="text-white">FOUNDERS MESSAGE</h2>
              </div>
            </div>
          </div>
                  <p>
                  Mr. Jitendra Bhhati, our visionary co-founder and CEO, has leveraged his 20+ years of corporate expertise to revolutionize the kabaddi landscape. Born and raised in a defense background family, Jitendra imbibed the values of sportsmanship, teamwork, and determination, which he has successfully translated into the JD Noida Ninjas' winning ethos. His corporate acumen has introduced a professional culture to the academy, attracting top talent and sponsors. Under his leadership, the team has gained recognition and fame, inspiring a new generation of players. Jitendra's passion extends beyond the sport, as he is committed to driving the youth of the country towards de-addiction and empowering them with a winning attitude. His tireless efforts have made a significant impact on the lives of many, earning him a respected position in the kabaddi fraternity.
                  </p>
                 

                  <div className="founderline"></div>
                  <div className="designation">
                    <h5> Mr. Jitendra Bhhati </h5>
                    <h6>YAMUNA YODHAS</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
