import React, { useState } from "react";
import Nav from "../Include/Nav";
import Footer from "../Include/Footer1";
import breadcumb from '../Frame 373 (1).png'

import auction1 from './JJ (1).png'
import auction3 from './JJ (3).png'
import auction4 from './JJ (4).png'
import auction5 from './JJ (5).png'
import auction6 from './JJ (6).png'
import auction7 from './JJ (7).png'
import auction8 from './JJ (8).png'
import auction9 from './JJ (9).png'
import auction10 from './JJ (10).png'
import auction11 from './JJ (11).png'
import auction12 from './JJ (12).png'
import auction13 from './JJ (13).png'
import auction14 from './JJ (14).png'
import auction15 from './JJ (15).png'

import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
export default function Auction({ thumbnail, videoUrl }) {
  const [isPlaying, setIsPlaying] = useState(false);


  const auctionImages = [
    auction1,
    auction3,
    auction4,
    auction5,
    auction6,
    auction7,
    auction8,
    auction9,
    auction10,
    auction11,
    auction12,
    auction13,
    auction14,
    auction15,

  ];



  const options = {
    loop: true,
    margin:30,
    nav: false,
    loop: true,
    autoplay: true, // Enable autoplay
    autoplayTimeout: 3000, // Autoplay interval in milliseconds (4 seconds)
    responsive: {
      0: {
        items: 2.3,
        margin: 20,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4.5,
      },
    },
  };

  return (
    <>
    
      <Nav />
 
      <section className="breadcrumb">
        <img src={breadcumb} className="img-fluid" alt="Breadcrumb Image" />
        <div className="breadcrumb-caption">
          <div className="d-flex">
            <div className="newarrows">
              <h2 className="text-white">SQUAD</h2>
              <div className="linetilte"></div>
            </div>
           
          </div>
        </div>
      </section>
  

      <section className="aboutfounder">
      <div className="titlecaption">
            <div className="d-flex">
              <div className="newarrows">
                <h2 className="text-white">
              Players
                </h2>
                <div className="linetilte"></div>
              </div>
            </div>
          </div>
<br></br>
        <div className="container-fluid">
         <div className="row">
      {auctionImages.map((image, index) => (
        <div className="col-6 col-md-3" key={index}>
          <img src={image} className="img-fluid photoauction" alt={`Auction ${index + 1}`} />
        </div>
      ))}
    </div>
        </div>
      </section>
      <Footer />



    </>
  );
}
