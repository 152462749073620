import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import arrow from "./arrowpreheader.svg";
import logo from '../noida-ninjas (2).png'
import breadcumb from '../Frame 373 (1).png'


export default function Nav() {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState("");

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="preheader mobilenone">
        <div className="row">
          <div className="col-5 align-self-center">
            <div className="preheadingtext">
              <p>
              Catch us playing live on 11th July - 25th July on Sony Sports and Fancode.
              </p>
            </div>
          </div>
          <div className="col-5 align-self-center">
            <div className="preheadingtext">
              <p>
              Stay tuned on instagram handle @yamunayodhas for latest updates!







              </p>
            </div>
          </div>
          
        </div>
      </div>
      <nav className="navbar navbar-expand-lg navbar-light bg-light mobilenone">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <Link className="navbar-brand" to="/">
              <img
                src={logo}
                alt="Center Logo"
                className="img-fluid upkllogo mobilenone"
              />
            </Link>
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <Link
                  className={
                    location.pathname === "/" ? "active nav-link" : "nav-link"
                  }
                  to="/"
                >
                  HOME
                </Link>
              </li>



              <li className="nav-item">
                <Link
                  className={
                    location.pathname === "/about" ? "active nav-link" : "nav-link"
                  }
                  to="/about"
                >
                  ABOUT
                </Link>
              </li>


              
             

          
              
              <li className="nav-item">
                <Link
                  className={
                    location.pathname === "/auction"
                      ? "active nav-link"
                      : "nav-link"
                  }
                  to="/auction"
                >
                  SQUAD
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    location.pathname === "/standing"
                      ? "active nav-link"
                      : "nav-link"
                  }
                  to="/standing"
                >
                  STANDINGS
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={
                    location.pathname === "/team"
                      ? "active nav-link"
                      : "nav-link"
                  }
                  to="/news"
                >
                  NEWS 
                </Link>
              </li>


             
            </ul>
        
          </div>
        </div>
      </nav>

      <nav className="navigation desktopnone navbar" role=" ">
        <div id="menuToggle">
          <input type="checkbox" />
          <span></span>
          <span></span>
          <span></span>
          <ul id="menu">
            <li
              className={`nav-item ${
                location.pathname === "/" ? "active" : ""
              }`}
            >
              <Link className="nav-link" href="/">
                HOME
              </Link>
            </li>
            <li
              className={`nav-item ${
                location.pathname === "/standing" ? "active" : ""
              }`}
            >
              <Link className="nav-link" to="/standing">
                STANDINGS
              </Link>
            </li>
            <li
              className={`nav-item ${
                location.pathname === "/auction" ? "active" : ""
              }`}
            >
              <Link className="nav-link" to="/auction">
                Squad
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/news">
                NEWS
              </Link>
            </li>

          

            
          </ul>
        </div>
        <div className="mblogo">
          <Link to="/">
            {" "}
            <img src={logo} />
          </Link>{" "}
        </div>
      </nav>
    </>
  );
}
