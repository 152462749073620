import React, { useState, useEffect } from "react";
import arrow from "../arrow.svg";
import { Link } from "react-router-dom";
import Banner1 from "../noida.jpg";

export default function Banner() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStandings = async () => {
      try {
        const response = await fetch(
          'https://dev-upkl.upkl.in/api/resource/Banners?fields=["*"]'
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setStandings(data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchStandings();
  }, []);

  return (
    <>
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-ride="carousel"
        data-interval="5000"
      >
        <ol className="carousel-indicators">
        
        </ol>
        <div className="carousel-inner">
            <div
            >
                <div className="banner-container">
                  {/* <div className="banner-overlay"></div> */}
                  <img
                    src={Banner1}
                    alt="Banner"
                    className="banner-image"
                  />
                </div>
            </div>
        </div>
        <a
          className="carousel-control-prev"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only"></span>
        </a>
        <a
          className="carousel-control-next"
          href="#carouselExampleIndicators"
          role="button"
          data-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only"></span>
        </a>
      </div>
    </>
  );
}
