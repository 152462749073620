import React, { useState, useEffect } from "react";
import arrow from "../arrow.svg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import tv from "../teambg.svg";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Link } from "react-router-dom";

export default function New() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const fetchStandings = async () => {
      try {
        const response = await fetch(
            'https://dev-upkl.upkl.in/api/resource/News%20and%20Updates?fields=["*"]'
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setStandings(data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchStandings();
  }, []); 

  

  const options3 = {
    loop: true,
    margin: 20,
    nav: false,
    loop: true,
    dots: false,
    autoplay: true, // Enable autoplay
    autoplayTimeout: 4200,
    responsive: {
      0: {
        items: 1.6,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };
  return (
    <>
      <section className="tvsection" id="news">
        <div className="titlecaption">
          <div className="d-flex">
            <div className="newarrows">
              <h2 className="text-white">LATEST NEWS</h2>
              <div className="linetilte"></div>
            </div>
           
          </div>
        </div>

        <OwlCarousel className="owl-theme" {...options3}>
          {standings.length > 0 ? (
            standings.map((team, index) => (
              <Link target="_blank" to={team.news_links}>
              <div class="card">
                <img class="card-img-top"  src={`https://dev-upkl.upkl.in/${team.image}`} alt="Card image cap" />
                <div class="card-body">

                  <p class="card-text">
                  {team.channel} &nbsp;
                    <span className="text-white datetv">
                       | 3 June 2024
                    </span>
                  </p>
                </div>
              </div></Link>
            ))
          ) : (
            <SkeletonTheme baseColor="#81290a" highlightColor="#9a3d1c">
              <div className="image-container">
                <div className="overlay"></div>
                <Skeleton width={240} height={300} />{" "}
                {/* Adjust width and height as per your design */}
              </div>
            </SkeletonTheme>
          )}
        </OwlCarousel>

     
      </section>
    </>
  );
}
