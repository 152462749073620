import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import arrow from "../arrow.svg";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function Fixture() {
  const [standings, setStandings] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchStandings = async () => {
    try {
      const response = await fetch(
        'https://dev-upkl.upkl.in/api/resource/FIXTURES?or_filters=[["team_a_name", "=", "NOIDA NINJAS"], ["team_b_name", "=", "NOIDA NINJAS"]]&fields=["*"]&limit_page_length=100'
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const data = await response.json();
      const sortedData = data.data.sort((a, b) => a.match_day - b.match_day);
      setStandings(sortedData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStandings();
  }, []);

  const options = {
    loop: true,
    margin: 10,
    nav: false,
    // autoplay: true, // Enable autoplay
    // autoplayTimeout: 3000, // Autoplay interval in milliseconds (3 seconds)
    responsive: {
      0: {
        items: 1.4,
        margin: 5,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 3.5,
      },
    },
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const dayOptions = { weekday: "long" };
    const dateOptions = { year: "numeric", month: "long", day: "numeric" };
    return {
      day: date.toLocaleDateString("en-US", dayOptions),
      date: date.toLocaleDateString("en-US", dateOptions),
    };
  };

  return (
    <>
      <section className="fixturessection">
        <div className="titlecaption">
          <div className="d-flex">
            <div className="newarrows">
              <h2 className="text-white">FIXTURES</h2>
              <div className="linetilte"></div>
            
            </div>
           
          </div>
        </div>

        <OwlCarousel className="owl-theme" {...options}>
          {standings.length > 0 ? (
            standings.map((team, index) => {
              const { day, date } = formatDate(team.match_date);
              return (
                <div className="item" key={index}>
                  <div className="fixturehome">
                    <div className="container-fluid p-0">
                      <div className="row">
                        <div className="fixturecard">
                          <h5 className="text-center">
                            MATCH {index+1}
                          </h5>
                         
                          <div className="row">
                            <div className="col-5">
                              <div className="fixtureleft">
                                <img
                                  src={`https://dev-upkl.upkl.in/${team.team_a_logo}`}
                                  className="img-fluid"
                                  alt="Logo"
                                />
                              </div>
                            </div>
                            <div className="col-2 align-self-center">
                              <div className="fixturecenter">
                                <h2>Vs</h2>
                              </div>
                            </div>
                            <div className="col-5">
                              <div className="fixtureleft">
                                <img
                                  src={`https://dev-upkl.upkl.in/${team.team_b_logo}`}
                                  className="img-fluid"
                                  alt="Logo"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-5 p-0">
                              
                            </div>
                            <div className="col-2">
                              <div className="fixturecenter">
                                <h3></h3>
                              </div>
                            </div>
                            <div className="col-5">
                             
                            </div>
                          </div>
                          {/* <button className='moreinfo'>MATCH INFO</button> */}
                        </div>
                      </div>

                      <div className="stadium">
                      <p>
                            {date},{day.toUpperCase()}
                            <br />{" "}
                            <b className="boldnew">Time : {team.match_time}</b>
                          </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <SkeletonTheme baseColor="#81290a" highlightColor="#9a3d1c">
              <div className="image-container">
                <div className="overlay"></div>
                <Skeleton width={260} height={400} />{" "}
                {/* Adjust width and height as per your design */}
              </div>
            </SkeletonTheme>
          )}
        </OwlCarousel>
      </section>
    </>
  );
}
